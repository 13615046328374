<template>
    <div class="authincation min-vh-100">
        <div class="min-vh-100">
            <router-view/>
        </div>
    </div>
</template>

<script>
import firebase from "../../config/firebase"

export default {
    name: "Container",
    mounted(){
        if(firebase.auth().currentUser){
            return this.$router.push({name: 'Dashboard'})
        }
        const dash_scripts = require("../../assets/js/custom-dashboard");
        dash_scripts();
    }
}
</script>

<style scoped>

</style>