module.exports =  function(){
    $(function(){
        $('head').append(`
            <link rel="stylesheet" href="/assets/dashboard/vendor/chartist/css/chartist.min.css">
            <link rel="stylesheet" href="/assets/dashboard/vendor/toastr/css/toastr.min.css">
            <link href="/assets/dashboard/vendor/bootstrap-select/dist/css/bootstrap-select.min.css" rel="stylesheet">
            <link href="/assets/dashboard/vendor/owl-carousel/owl.carousel.css" rel="stylesheet">
            <link href="/assets/dashboard/css/style.css" rel="stylesheet">
        `);

        $('body').append(`
            <script src="/assets/dashboard/vendor/bootstrap-select/dist/js/bootstrap-select.min.js"></script>
            <script src="/assets/dashboard/vendor/toastr/js/toastr.min.js"></script>
            <script src="/assets/dashboard/vendor/peity/jquery.peity.min.js"></script>
            <script src="/assets/dashboard/js/dashboard/dashboard-1.js"></script>
            <script src="/assets/dashboard/js/custom.min.js"></script>
            <script src="/assets/dashboard/js/deznav-init.js"></script>
        `)
    })
}